import React, { useState, useEffect } from 'react';
import CirclesSection from '../CirclesSection/CirclesSection';
import { circlesData } from '../../data/dataManager'; // Import circlesData
import './CirclesPage.css';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import closeIcon from '../../images/close.png';
import axios from 'axios';

function CirclesPage({ language }) {
  const { t } = useTranslation();
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [sortOrder, setSortOrder] = useState('descending');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [formData, setFormData] = useState({
    yourName: '',
    yourEmail: '',
    //topic: '',
    description: '',
    targetAudience: '',
    checkboxes: {
      all: false,
      'pre-k': false,
      '7-8': false,
      '9-11': false,
      '12-14': false,
      '15-18': false,
    },
  });

  const [submissionStatus, setSubmissionStatus] = useState(null);

  const resetForm = () => {
    setFormData({
      yourName: '',
      yourEmail: '',
      //topic: '',
      description: '',
      targetAudience: '',
      checkboxes: {
        all: false,
        'pre-k': false,
        '7-8': false,
        '9-11': false,
        '12-14': false,
        '15-18': false,
      },
    });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    let newCheckboxes = { ...formData.checkboxes };

    if (id === 'all') {
      newCheckboxes = Object.keys(newCheckboxes).reduce((acc, key) => {
        acc[key] = checked;
        return acc;
      }, {});
    } else {
      newCheckboxes[id] = checked;
      newCheckboxes.all = Object.keys(newCheckboxes).every((key) => key === 'all' || newCheckboxes[key]);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      checkboxes: newCheckboxes,
    }));
  };

  useEffect(() => {
    if (language === 'es') {
      setSelectedLanguage('es');
    } else {
      setSelectedLanguage('en');
    }
  }, [language]);

  const handleAddButtonClick = () => {
    setModalIsOpen(true);
  };

  const filterCircles = () => {
    let filteredCircles = circlesData.filter(
      (circle) =>
        (selectedTopic === '' || circle.topic === selectedTopic) &&
        (selectedLanguage === '' || circle.language.toLowerCase() === selectedLanguage)
    );
  
    // Sort circles based on sortOrder state
    filteredCircles.sort((a, b) => {
      if (sortOrder === 'ascending') {
        return a.enrolled - b.enrolled;
      } else {
        return b.enrolled - a.enrolled;
      }
    });

    return filteredCircles;
  };

  const [formErrors, setFormErrors] = useState({
    yourName: '',
    yourEmail: '',
    description: '',
  });

  const emailIsValid = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const sendForm = async () => {
    let errors = {};

    if (!emailIsValid(formData.yourEmail)) {
      errors.yourEmail = t('error_invalid_email');
    } else {
      errors.yourEmail = '';
    }

    if (!formData.description.trim()) {
      errors.description = t('error_required_field');
    } else {
      errors.description = '';
    }

    setFormErrors(errors);

    if (Object.values(errors).some((error) => error)) {
      return;
    }

    try {
      const response = await axios.post('https://ihtuqcw0cb.execute-api.us-west-1.amazonaws.com/dev', {
        yourName: formData.yourName,
        yourEmail: formData.yourEmail,
        //topic: formData.topic,
        description: formData.description,
        targetAudience: formData.targetAudience,
        checkboxes: formData.checkboxes,
      });
  
      if (response.status === 200) {
        setSubmissionStatus('success');
        resetForm();
        setTimeout(() => {
          setSubmissionStatus(null);
          setModalIsOpen(false);
        }, 3000);
      } else {
        setSubmissionStatus('error');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmissionStatus('error');
    }
  };
  



  const handleTopicChange = (event) => {
    setSelectedTopic(event.target.value);
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  // Add function to handle sortOrder change
  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  const filteredCircles = filterCircles();

  

  return (
    <div className="circles-page">

    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      overlayClassName="ReactModal__Overlay"
      className="ReactModal__Content"
    >
      <img
        src={closeIcon}
        alt="Close"
        className="modal-close-icon"
        onClick={() => setModalIsOpen(false)}
      />

      <h2>{t('add_your_circle_title')}</h2>
      <p>{t('skip_line')}</p>

      <label htmlFor="topic" className="modal-label"><b>{t('your_name')}</b></label>
      <input type="text" id="yourName" className="modal-input topic" value={formData.yourName} onChange={handleInputChange} />
      <br />

      <label htmlFor="yourEmail" className="modal-label">
        <b>{t('your_email')}</b>
      </label>
      <input
        type="text"
        id="yourEmail"
        className="modal-input topic"
        value={formData.yourEmail}
        onChange={handleInputChange}
      />
      {formErrors.yourEmail && (
        <p className="error-message">{formErrors.yourEmail}</p>
      )}
      <br />
      
      {/*
      <label htmlFor="topic" className="modal-label"><b>{t('topic_suggestion')}</b></label>
      <input type="text" id="topic" className="modal-input topic" value={formData.topic} onChange={handleInputChange} />
      <br />*/}
      
      <label htmlFor="description" className="modal-label">
        <b>{t('description_suggestion')}</b>
      </label>
      <textarea
        id="description"
        className="modal-input description"
        value={formData.description}
        onChange={handleInputChange}
      ></textarea>
      {formErrors.description && (
        <p className="error-message">{formErrors.description}</p>
      )}
      <br />

      <label htmlFor="description" className="modal-label"><b>{t('target_audience')}</b></label>
      <textarea id="targetAudience" className="modal-input description" value={formData.targetAudience} onChange={handleInputChange}></textarea>
      <br />

      <label className="modal-label"><b>{t('target_age_students')}</b></label>
      <div className="modal-checkbox-wrapper">
      <div>
        <input type="checkbox" id="pre-k" checked={formData.checkboxes['pre-k']} onChange={handleCheckboxChange} />
        <label htmlFor="pre-k" className="modal-checkbox-label">Pre-K</label>
        <br />
        <input type="checkbox" id="7-8" checked={formData.checkboxes['7-8']} onChange={handleCheckboxChange} />
        <label htmlFor="7-8" className="modal-checkbox-label">7-8</label>
        <br />
      </div>
      <div>
        <input type="checkbox" id="9-11" checked={formData.checkboxes['9-11']} onChange={handleCheckboxChange} />
        <label htmlFor="9-11" className="modal-checkbox-label">9-11</label>
        <br />
        <input type="checkbox" id="12-14" checked={formData.checkboxes['12-14']} onChange={handleCheckboxChange} />
        <label htmlFor="12-14" className="modal-checkbox-label">12-14</label>
        <br />
      </div>
      <div>
        <input type="checkbox" id="15-18" checked={formData.checkboxes['15-18']} onChange={handleCheckboxChange} />
        <label htmlFor="15-18" className="modal-checkbox-label">15-18</label>
        <br />
        <input type="checkbox" id="all" checked={formData.checkboxes.all} onChange={handleCheckboxChange} />
        <label htmlFor="all" className="modal-checkbox-label">All</label>
        <br />
      </div>
    </div>

    <div className="button-and-message">
      <button onClick={sendForm} className="btn">{t('send')}</button>

      {submissionStatus === 'success' ? (
        <p className="submission-status success">{t('form_submission_success')}</p>
      ) : null}
    </div>
    
    </Modal>

      <div className="circles-page-content">
        <h2>{t('all_circles')}</h2>
        <div className="filters">
          {/* Topic filter */}
          <div className="filter">
            <label htmlFor="topic-filter">{t('topic')}:</label>
            <select id="topic-filter" value={selectedTopic} onChange={handleTopicChange}>
              <option value="">{t('all')}</option>
              <option value="literacy">{t('literacy')}</option>
              <option value="special-ed">{t('special-ed')}</option>
              <option value="math">{t('math')}</option>
              <option value="science">{t('science')}</option>
              <option value="ed-tech">{t('ed-tech')}</option>
              <option value="innov-method">{t('innov-method')}</option>
              <option value="sel">{t('sel')}</option>
              <option value="assessment">{t('assessment')}</option>
              {/* 
              <option value="social-studies">{t('social-studies')}</option>
              <option value="classroom-management">{t('classroom-management')}</option>
              <option value="arts-music">{t('arts-music')}</option> 
              */}
            </select>
          </div>
          {/* Language filter */}
          <div className="filter">
            <label htmlFor="language-filter">{t('language')}:</label>
            <select id="language-filter" value={selectedLanguage} onChange={handleLanguageChange}>
              <option value="">{t('all')}</option>
              {/* Add languages as options */}
              <option value="en">{t('languages.en')}</option>
              <option value="es">{t('languages.es')}</option>
            </select>
          </div>
          <div className="filter">
            <label htmlFor="sort-order-filter">{t('sort_by_enrolled')}:</label>
            <select id="sort-order-filter" value={sortOrder} onChange={handleSortOrderChange}>
              <option value="ascending">{t('ascending')}</option>
              <option value="descending">{t('descending')}</option>
            </select>
          </div>
        </div>
        <CirclesSection
          circlesData={[{ isAddCircle: true }, ...filteredCircles]}
          language=""
          hideTitle={true}
          onAddButtonClick={handleAddButtonClick} // Pass down the function as a prop
        />
      </div>
    </div>
  );
}

export default CirclesPage;