import React from 'react';
import aboutTeacherCirclesImage from '../../images/about-teacher-circles.png';
import { useTranslation } from 'react-i18next';
import './About.css';

function About() {
  const { t } = useTranslation();

  return (
    <section className="about-section">
      <img src={aboutTeacherCirclesImage} alt={t('about_image_alt')} className="about-image" />
      <h2>{t('about_title')}</h2>
      <p>
        {t('about_paragraph_1')} <br /><br />
        {t('about_paragraph_2')} <br /><br />
        {t('about_paragraph_3')}
        <ol>
          <li><strong>{t('about_benefit_1_title')}:</strong> {t('about_benefit_1_description')}</li>
          <li><strong>{t('about_benefit_2_title')}:</strong> {t('about_benefit_2_description')}</li>
          <li><strong>{t('about_benefit_3_title')}:</strong> {t('about_benefit_3_description')}</li>
          <li><strong>{t('about_benefit_4_title')}:</strong> {t('about_benefit_4_description')}</li>
          <li><strong>{t('about_benefit_5_title')}:</strong> {t('about_benefit_5_description')}</li>
        </ol>
        {t('about_paragraph_4')}
      </p>
    </section>
  );
}

export default About;



