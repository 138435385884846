import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { useTranslation } from 'react-i18next';
import logoFooter from '../../images/teacher-circles-logo-footer.png';


function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="container">
         <Link to="/" className="logoFooter">
          <img src={logoFooter} alt={t('teaching_circles_logo')} />
        </Link>
        <nav className="footer-nav">
          <ul>
            <li>
              <Link to="/">{t('home')}</Link>
            </li>
            <li>
              <Link to="/circles">{t('circles')}</Link>
            </li>
            <li>
              <Link to="/institutional-service">{t('institutions')}</Link>
            </li>
            <li>
              <Link to="/about">{t('about')}</Link>
            </li>
            <li>
              <Link to="/contact">{t('contact')}</Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="container2">
      <p>&copy; {new Date().getFullYear()} {t('teaching_circles')}. {t('all_rights_reserved')}</p>
      </div>
    </footer>
  );
}

export default Footer;


