const circlesData = [
  {
    id: 1,
    title: 'Redacción de ensayos eficaces',
    studentsAge: '14 - 18',
    shortDescription: 'Perfecciona la redacción de ensayos de tus estudiantes con técnicas y estrategias efectivas.',
    descriptionP1: 'En este Círculo, los participantes investigarán, compartirán experiencias y reflexionarán sobre cómo fortalecer las habilidades de escritura de sus estudiantes de 14 - 18 años mediante la enseñanza de técnicas y estrategias efectivas para la redacción de ensayos.',
    descriptionP2: 'Los principales temas a tratar son:',
    descriptionItems: [
      'Estructura y organización del ensayo',
      'Desarrollo de tesis y argumentos sólidos',
      'Uso de ejemplos y evidencia',
      'Estilo y tono en la redacción',
      'Argumentación y contraargumentación',
    ],
    language: 'es',
    backgroundColor: '#FFF2DB',
    topic: 'literacy',
    accessInstructions: 'TC1184',
    enrolled: 42,
    guideUrl: 'https://docs.google.com/document/d/18DyZsK3FPsYiPK0vT0qtOTXHxAujpFi2JXxGrsnB10g/edit?usp=sharing',
  },
  {
    id: 2,
    title: "Mentalidad de crecimiento",
    studentsAge: "7 - 18",
    shortDescription: "Fomenta la mentalidad de crecimiento en estudiantes para mejorar el desempeño académico.",
    descriptionP1: "En este Círculo, los docentes se dedicarán a investigar, compartir experiencias y reflexionar acerca de cómo fomentar una mentalidad de crecimiento en sus estudiantes. Dicha mentalidad constituye la base de habilidades y actitudes clave, tales como la resiliencia, la perseverancia y la confianza en sí mismos. Al adquirir este conocimiento, los docentes estarán promoviendo una cultura de aprendizaje continuo, en la cual los estudiantes no temen cometer errores o enfrentarse a desafíos difíciles; por el contrario, los perciben como oportunidades para crecer y mejorar.",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "Concepto de mentalidad fija y mentalidad de crecimiento",
    "Importancia de esfuerzo y perseverancia",
    "Elogio y retroalimentación constructiva",
    "Modelar mentalidad de crecimiento",
    "Integración de la mentalidad de crecimiento en el currículo y la evaluación"
    ],
    language: "es",
    backgroundColor: '#D5E7D4',
    topic: "sel",
    accessInstructions: 'TC1243',
    enrolled: 87,
    guideUrl: 'https://docs.google.com/document/d/1rWFrU7UnjJSS4BUmQhFrAU3R6fbp99UPuK8IluVS_50/edit?usp=sharing',
    },
    {
    id: 3,
    title: "Integración de tecnologías emergentes en el aula",
    studentsAge: "12 - 18",
    shortDescription: "Incorpora tecnologías emergentes para mejorar la enseñanza y el aprendizaje.",
    descriptionP1: "En este Círculo, los docentes se centrarán en investigar, compartir experiencias y reflexionar sobre la integración de tecnologías emergentes en el aula, abarcando temas como realidad virtual, inteligencia artificial y gamificación. Estas innovaciones tienen el potencial de transformar la educación, ofreciendo experiencias de aprendizaje más inmersivas, personalizadas y motivadoras para los estudiantes. Al dominar y aplicar estas tecnologías, los docentes estarán impulsando una cultura de aprendizaje avanzada, en la que los estudiantes se adaptan rápidamente a los cambios, enfrentan desafíos con entusiasmo y adquieren habilidades relevantes para el siglo XXI.",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "Realidad virtual y aumentada en la educación",
    "Inteligencia artificial y aprendizaje adaptativo",
    "Gamificación en la enseñanza",
    "Plataformas de aprendizaje en línea",
    "Analítica del aprendizaje y evaluación basada en datos"
    ],
    language: "es",
    backgroundColor: '#FFDBFE',
    topic: "ed-tech",
    accessInstructions: 'TC1873',
    enrolled: 58,
    guideUrl: 'https://docs.google.com/document/d/1Wu4IH6P5ox_yWHEfuXueXqEOD-lEjaLT-egYEtZk_z4/edit?usp=sharing',
    },
    {
    id: 4,
    title: "Integración de estudiantes con NEE",
    studentsAge: "7 - 18",
    shortDescription: "Celebra la diversidad en el aula, garantizando el éxito de todos los estudiantes.",
    descriptionP1: "En este Círculo, los docentes se dedicarán a investigar, compartir experiencias y reflexionar sobre la integración de estudiantes con Necesidades Educativas Especiales (NEE) en el aula. La inclusión educativa es fundamental para garantizar que todos los estudiantes, independientemente de sus habilidades, tengan igualdad de oportunidades para aprender y desarrollarse. Al implementar estrategias pedagógicas adaptadas y fomentar un entorno inclusivo, los docentes estarán promoviendo una cultura de aprendizaje diversa y solidaria, en la que los estudiantes con NEE puedan superar barreras y alcanzar su máximo potencial. Además, este enfoque enriquece la experiencia educativa de todos los estudiantes, fomentando la empatía, el respeto y la colaboración entre compañeros de distintas habilidades y necesidades.",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "Estilos de aprendizaje y enseñanza diferenciada",
    "Fomento de la inclusión y el respeto en el aula",
    "Colaboración con profesionales y familiares",
    "Tecnologías de apoyo y recursos didácticos",
    "Transición y orientación vocacional para estudiantes con NEE"
    ],
    language: "es",
    backgroundColor: '#E5F8BE',
    topic: "special-ed",
    accessInstructions: 'TC1694',
    enrolled: 74,
    guideUrl: 'https://docs.google.com/document/d/1W6AfxcKbbQtVbazNeqMNhQEfD8fB34MSCndTZroC3bk/edit?usp=sharing',
    },
    {
    id: 5,
    title: "Huertos en la escuela",
    studentsAge: "7-12",
    shortDescription: "Cultiva la conexión de tus estudiantes con la naturaleza.",
    descriptionP1: "En este Círculo, los docentes descubrirán cómo implementar huertos escolares para promover el aprendizaje práctico y la conexión con el medio ambiente. Los huertos escolares ofrecen una oportunidad para enseñar sobre ecología, agricultura y responsabilidad social, al tiempo que fomentan hábitos alimenticios saludables en los estudiantes.",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "Beneficios de los huertos escolares",
    "Planificación y diseño del huerto",
    "Selección de cultivos",
    "Preparación del suelo",
    "Cuidado y mantenimiento del huerto",
    ],
    language: "es",
    backgroundColor: '#FED6C4',
    topic: "science",
    accessInstructions: 'Pronto disponible',
    enrolled: 103,
    guideUrl: 'https://docs.google.com/document/d/1jjc_zC4otu5fnVQjXRrlExsKZkCw2gL8nBO4F2_NboM/edit?usp=sharing',
    },
    {
    id: 6,
    title: "Estrategias de evaluación formativa y sumativa",
    studentsAge: "12-18",
    shortDescription: "Optimiza tu enseñanza con enfoques de evaluación efectivos.",
    descriptionP1: "Este Círculo examina diferentes enfoques de evaluación para medir el progreso de los estudiantes y optimizar la enseñanza, combinando la retroalimentación constante con la evaluación de logros al concluir una unidad o periodo académico.",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "Evaluación basada en competencias",
    "Portafolios digitales y evaluación del aprendizaje",
    "Pruebas de desempeño y evaluaciones auténticas",
    "Autoevaluación y coevaluación",
    "Evaluación entre pares y aprendizaje colaborativo",
    ],
    language: "es",
    backgroundColor: '#BEDCFF',
    topic: "assessment",
    accessInstructions: 'Pronto disponible',
    enrolled: 80,
    guideUrl: 'https://docs.google.com/document/d/1bHvl0UTKc8lRsWhMXBvWA1TC_7WqInIfFlVZtoycGQM/edit?usp=sharing',
    },
    {
    id: 7,
    title: "Enfoques pedagógicos para la enseñanza inclusiva",
    studentsAge: "12-18",
    shortDescription: "Construye un aula enriquecedora y solidaria.",
    descriptionP1: "En este Círculo, los docentes aprenderán sobre diversas metodologías y prácticas que fomentan la inclusión y equidad en el aula, adaptándose a las necesidades de todos los estudiantes y creando un ambiente enriquecedor y solidario. El objetivo es garantizar que cada estudiante, independientemente de sus habilidades y necesidades, tenga acceso a oportunidades educativas equitativas y pueda alcanzar su máximo potencial.",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "Diversidad cultural y estrategias de enseñanza multiculturales",
    "Estrategias para la enseñanza de estudiantes multilingües",
    "Equidad de género y diversidad en el aula",
    "Estilos de aprendizaje y diferenciación de la enseñanza",
    "Fomento de la equidad y justicia social en el aula",
    ],
    language: "es",
    backgroundColor: '#E5F8BE',
    topic: "special-ed",
    accessInstructions: 'TC1283',
    enrolled: 85,
    guideUrl: 'https://docs.google.com/document/d/1LzfAFdlOeZQTpTrobmDUnru5Txu_VDo4yphOEZ51EKQ/edit?usp=sharing',
    },
    {
    id: 8,
    title: "Mejorar la comprensión lectora",
    studentsAge: "9-12",
    shortDescription: "Eleva la capacidad de tus estudiantes para analizar e interpretar textos con éxito.",
    descriptionP1: "Este Círculo se enfoca en técnicas y estrategias efectivas para potenciar la comprensión lectora en los estudiantes, abordando aspectos como la velocidad de lectura, la retención de información y la interpretación de textos. Al aplicar estos métodos, los docentes pueden apoyar a los estudiantes en su proceso de aprendizaje y mejorar su capacidad para analizar y comprender textos de diferentes niveles de complejidad.",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "Estrategias de lectura activa y metacognición",
    "Identificación de ideas principales y secundarias",
    "Análisis de estructura y géneros textuales",
    "Comparación y síntesis de información",
    "Fomento de la motivación y el amor por la lectura",
    ],
    language: "es",
    backgroundColor: '#FFF2DB',
    topic: "literacy",
    accessInstructions: 'Pronto disponible',
    enrolled: 98,
    guideUrl: 'https://docs.google.com/document/d/1ieQr9H_7oHlrwqhLoufZdIGZJwWq-QdU5pAtCrlmTVw/edit?usp=sharing',
    },
    {
    id: 9,
    title: "Educación para la ciudadanía digital",
    studentsAge: "12-18",
    shortDescription: "Empodera a tus estudiantes para que sean ciudadanos digitales responsables.",
    descriptionP1: "Este Círculo trata sobre cómo enseñar a los estudiantes a ser ciudadanos digitales responsables, promoviendo el uso seguro y ético de la tecnología, la protección de la privacidad y la importancia de la empatía en el entorno digital. Estos aspectos son clave en un mundo donde el ámbito digital adquiere cada vez más relevancia en la vida de niños y jóvenes.",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "Comunicación positiva en línea",
    "Seguridad en línea y privacidad digital",
    "Identificación y prevención del ciberacoso",
    "Derechos de autor y uso ético de la información",
    "Cómo detectar fake news y desinformación",
    ],
    language: "es",
    backgroundColor: '#FFDBFE',
    topic: "ed-tech",
    accessInstructions: 'TC0842',
    enrolled: 34,
    guideUrl: 'https://docs.google.com/document/d/1JO5GDcvbHyGKw_FP4NXyapiGho8Fx80aO8Ojt-eI4Vw/edit?usp=sharing',
    },
    {
    id: 10,
    title: "Educación financiera para jóvenes",
    studentsAge: "12-18",
    shortDescription: "Enseña conceptos fundamentales para el futuro financiero de tus estudiantes.",
    descriptionP1: "Este Círculo se enfoca en enseñar a los estudiantes conceptos fundamentales de finanzas personales, incluyendo ahorro, inversión, presupuesto y gestión del crédito, preparándolos para tomar decisiones informadas en el futuro.",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "Conceptos básicos de finanzas personales y ahorro",
    "Gestión del dinero y presupuestos",
    "Introducción a la inversión",
    "Educación sobre crédito y préstamos",
    "Planificación financiera",
    ],
    language: "es",
    backgroundColor: '#B5E9D7',
    topic: "math",
    accessInstructions: 'TC7231',
    enrolled: 57,
    guideUrl: 'https://docs.google.com/document/d/1X3ksRRhlBT8nBw30BTaOnUYfFaJIAtRcryS7mBGNoAI/edit?usp=sharing',
    },
    {
    id: 11,
    title: "Design Thinking",
    studentsAge: "14-18",
    shortDescription: "Fomenta la creatividad, el pensamiento crítico y la resolución de problemas en tus estudiantes a través del proceso de Design Thinking.",
    descriptionP1: "En este Círculo, los docentes aprenderán acerca del proceso de Design Thinking y cómo aplicarlo en el aula para fomentar la creatividad, el pensamiento crítico y la resolución de problemas en los estudiantes. Este enfoque brinda a los estudiantes herramientas prácticas y efectivas para abordar diversos proyectos en su vida futura.",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "Empatizar",
    "Definir",
    "Idear",
    "Prototipar",
    "Evaluar",
    ],
    language: "es",
    backgroundColor: '#C1EDFF',
    topic: "innov-method",
    accessInstructions: 'TC3614',
    enrolled: 29,
    guideUrl: 'https://docs.google.com/document/d/1mRChqWunov6Ru5_qMYXz6esJuhTrbV33ZlhueazNiYg/edit?usp=sharing',
    },
    {
    id: 12,
    title: "Desarrollo de habilidades sociales y emocionales",
    studentsAge: "7-18",
    shortDescription: "Enriquece la vida y el bienestar de tus estudiantes.",
    descriptionP1: "Este Círculo se centra en enseñar habilidades emocionales y sociales fundamentales, como la empatía, la comunicación efectiva, la autorregulación y la colaboración. Estas habilidades son esenciales para mejorar el bienestar y el éxito de los estudiantes a lo largo de su vida, permitiéndoles establecer relaciones saludables, manejar el estrés y enfrentar desafíos de manera efectiva.",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "Autoconocimiento y autogestión emocional",
    "Empatía y comprensión de los demás",
    "Resolución de conflictos y toma de decisiones",
    "Colaboración y trabajo en equipo",
    "Gestión del estrés y la ansiedad",
    ],
    language: "es",
    backgroundColor: '#D5E7D4',
    topic: "sel",
    accessInstructions: 'TC6028',
    enrolled: 92,
    guideUrl: 'https://docs.google.com/document/d/1lCj-G-hq-qKqIzBJcT_jTI5QqDD_4KuJeRsGOEHmRfY/edit?usp=sharing',
    },
    {
    id: 13,
    title: "Desarrollo de habilidades lectoras",
    studentsAge: "6-8",
    shortDescription: "Fortalece la base del éxito académico de tus estudiantes.",
    descriptionP1: "Este Círculo aborda estrategias y técnicas específicas para ayudar a los estudiantes a desarrollar habilidades de lectura sólidas, incluyendo comprensión, fluidez y expresión oral. La lectura es, quizás, el fundamento más importante para el éxito académico transversal y la formación integral de los estudiantes, ya que la lectura es una habilidad clave para acceder a conocimientos y desarrollar habilidades de pensamiento crítico.",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "Reconocimiento de letras y sonidos",
    "Desarrollo de habilidades fonéticas y fonológicas",
    "Comprensión de palabras y oraciones simples",
    "Fomento de la fluidez lectora",
    "Promoción de la lectura como actividad placentera y significativa",
    ],
    language: "es",
    backgroundColor: '#FFF2DB',
    topic: "literacy",
    accessInstructions: 'Pronto disponible',
    enrolled: 63,
    guideUrl: 'https://docs.google.com/document/d/1YL_Teh102zVIzCWBoYD3KswnZO196gKnCymuaYu5--I/edit?usp=sharing',
    },
    {
    id: 14,
    title: "Cultura Maker",
    studentsAge: "12-18",
    shortDescription: "Inspira la creatividad y el pensamiento crítico en tus estudiantes.",
    descriptionP1: "Este Círculo presenta a los docentes la cultura Maker y cómo fomentar un ambiente de aprendizaje basado en proyectos, donde los estudiantes pueden explorar, crear y experimentar en un entorno práctico y colaborativo. El enfoque Maker estimula la creatividad, el pensamiento crítico y la resolución de problemas en los estudiantes, preparándolos para un mundo laboral en constante cambio.",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "Introducción al movimiento maker y espacios maker en educación",
    "Diseño e impresión 3D en el aula",
    "Reciclaje y upcycling en proyectos educativos",
    "Proyectos de electrónica y programación con Arduino",
    "Diseño de soluciones y prototipado rápido",
    ],
    language: "es",
    backgroundColor: '#FFDBFE',
    topic: "ed-tech",
    accessInstructions: 'Pronto disponible',
    enrolled: 99,
    guideUrl: 'https://docs.google.com/document/d/1YyIQm8c9b_Q5WoHHxcU-genSTyZG4TZsuRtmJ9bUN3A/edit?usp=sharing',
    },
    {
    id: 15,
    title: "Colaboración en Google Classroom",
    studentsAge: "7-18",
    shortDescription: "Potencia el trabajo en equipo y la colaboración en tus clases.",
    descriptionP1: "En este Círculo, los docentes aprenderán cómo utilizar Google Classroom para fomentar el trabajo en equipo y la colaboración entre los estudiantes, aprovechando las herramientas digitales para mejorar la comunicación y el intercambio de ideas. Google Classroom es una plataforma cada vez más popular y es útil para los docentes no solo en el contexto de clases a distancia, sino también para gestionar tareas administrativas y comunicarse de manera ordenada con los estudiantes en clases presenciales.",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "Comunicación en Google Classroom",
    "Creación y asignación de tareas",
    "Uso de Google Docs para documentos colaborativos",
    "Gestión de clases y estudiantes",
    "Uso de Google Forms para evaluaciones",
    ],
    language: "es",
    backgroundColor: '#FFDBFE',
    topic: "ed-tech",
    accessInstructions: 'Pronto disponible',
    enrolled: 45,
    guideUrl: 'https://docs.google.com/document/d/10ibAVb0IGNU9oBtm8iBZ_C-TsaEDo6T5hyN--r6UYD8/edit?usp=sharing',
    },
    {
    id: 16,
    title: "Aprendizaje basado en la naturaleza",
    studentsAge: "7-18",
    shortDescription: "Fomenta la conciencia ecológica de tus estudiantes.",
    descriptionP1: "Este Círculo investiga cómo integrar el aprendizaje basado en la naturaleza y la educación medioambiental en el currículo escolar, fomentando la conexión de los estudiantes con el entorno natural y promoviendo la conciencia ecológica. Al llevar el aprendizaje al aire libre y tratar temas relevantes como la conservación y la sostenibilidad, los docentes pueden inspirar a los estudiantes a valorar y proteger nuestro planeta.",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "Exploración del entorno natural y actividades al aire libre",
    "Agricultura y jardinería escolar",
    "Estudios de flora y fauna local y su interacción con el ecosistema",
    "Gestión de recursos y reducción de residuos",
    "Acción comunitaria y proyectos de conservación ambiental",
    ],
    language: "es",
    backgroundColor: '#FED6C4',
    topic: "science",
    accessInstructions: 'Pronto disponible',
    enrolled: 23,
    guideUrl: 'https://docs.google.com/document/d/14by0ZSp-tdu65vAsq1KWRNtYx8Fod_-PGJHDdwHI8ww/edit?usp=sharing',
    },
  ];

  function getCircleById(id) {
    return circlesData.find((circle) => circle.id === parseInt(id, 10));
  }
  
  export { circlesData, getCircleById };

  /*
    "literacy": "Literacy and Language Arts", FFF2DB 
    "math": "Mathematics", B5E9D7
    "science": "Science", FED6C4
    "social-studies": "Social Studies and History", C6F8BE
    "arts-music": "Arts and Music", FFD7B4
    "special-ed": "Special Education and Inclusion", E5F8BE
    "ed-tech": "Technology in Education", FFDBFE
    "innov-method": "Innovative Methodologies", C1EDFF
    "sel": "Social and Emotional Learning", D5E7D4
    "assessment": "Assessment and Evaluation", BEDCFF
    "classroom-management": "Classroom Management", DBE4FF
  
    {
    id: <<Random number between 40 and 150>>,
    title: "<<TITLE>>",
    studentsAge: "<<AGE>>",
    shortDescription: "<<DESCRIPCION CORTA>>",
    descriptionP1: "<<DESCRIPCION LARGA>>",
    descriptionP2: "Los principales temas a tratar son:",
    descriptionItems: [
    "<<SUBTEMA>>",
    "<<SUBTEMA>>",
    "<<SUBTEMA>>",
    "<<SUBTEMA>>",
    "<<SUBTEMA>>",
    ],
    language: "es",
    backgroundColor: '<<#COLOR>>',
    topic: "<<TAG>>",
    accessInstructions: 'null',
    enrolled: <<Random number between 40 and 150>>,
    guideUrl: '<<LINK>>',
    },
    */