import React from 'react';
import './InstitutionalServicePage.css';
import { Link } from 'react-router-dom';
import exclusiveCirclesIcon from '../../images/exclusive-circles-icon.png';
import dashboardIcon from '../../images/dashboard-icon.png';
import customLearningIcon from '../../images/custom-learning-icon.png';
import { useTranslation } from 'react-i18next';

function InstitutionalServicePage() {
  const { t } = useTranslation();

  return (
    <div className="institutional-service-page">
      <div className="content-wrapper">
        {/* Overview Section */}
        <section className="overview-section">
          <h1>{t('empowering_educators')}</h1>
          <p>
            {t('overview_description')}
          </p>
        </section>

        {/* Features Section */}
        <section className="features-section">
          <h2>{t('key_features')}</h2>
          <div className="features-grid">
            <div className="feature-item">
              <img src={exclusiveCirclesIcon} alt={t('exclusive_circles')} />
              <h3>{t('exclusive_circles')}</h3>
              <p>
                {t('exclusive_circles_description')}
              </p>
            </div>
            <div className="feature-item">
              <img src={dashboardIcon} alt={t('comprehensive_dashboard')} />
              <h3>{t('comprehensive_dashboard')}</h3>
              <p>
                {t('dashboard_description')}
              </p>
            </div>
            <div className="feature-item">
              <img src={customLearningIcon} alt={t('customize_learning')} />
              <h3>{t('customize_learning')}</h3>
              <p>
                {t('customize_learning_description')}
              </p>
            </div>
          </div>
        </section>

        {/* Pricing and Plans Section */}
        <section className="pricing-plans-section">
          <h2>{t('ready_to_get_started')}</h2>
          <p>
            {t('pricing_plans_description')}
          </p>
          <Link to="/contact" className="btn pricing-cta">
            {t('contact_us')}
          </Link>
        </section>

      </div>
    </div>
  );
}

export default InstitutionalServicePage;