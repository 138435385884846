import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, Redirect } from 'react-router-dom';
import Header from './components/Header/Header';
import HeroSection from './components/HeroSection/HeroSection';
import StepsSection from './components/StepsSection/StepsSection';
import WhatAreCircles from './components/WhatAreCircles/WhatAreCircles';
import CirclesSection from './components/CirclesSection/CirclesSection';
import PLCDetails from './components/PLCDetails/PLCDetails';
import CirclesPage from './components/CirclesPage/CirclesPage';
import InstitutionalSection from './components/InstitutionalSection/InstitutionalSection';
import InstitutionalServicePage from './components/InstitutionalServicePage/InstitutionalServicePage';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import NotFound from './components/NotFound/NotFound';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import './App.css';
import { useEffect } from 'react';
import axios from 'axios';
import i18n from './i18n';

import { circlesData, getCircleById } from './data/dataManager';

const spanishSpeakingCountries = [
  'AR', // Argentina
  'BO', // Bolivia
  'CL', // Chile
  'CO', // Colombia
  'CR', // Costa Rica
  'CU', // Cuba
  'DO', // Dominican Republic
  'EC', // Ecuador
  'SV', // El Salvador
  'GQ', // Equatorial Guinea
  'GT', // Guatemala
  'HN', // Honduras
  'MX', // Mexico
  'NI', // Nicaragua
  'PA', // Panama
  'PY', // Paraguay
  'PE', // Peru
  'PR', // Puerto Rico
  'ES', // Spain
  'UY', // Uruguay
  'VE', // Venezuela
];

function App() {
  const [language, setLanguage] = useState('es');

  const updateMetaDescription = () => {
    const description = i18n.t('og_description');
    const metaDescription = document.getElementById('og-description');
    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    }
  };

  useEffect(() => {
    setLanguage('es');
    i18n.changeLanguage('es').then(() => updateMetaDescription());
  }, []);

/* Change to this config once our contents are in English
function App() {
  const [language, setLanguage] = useState('en');

  const updateMetaDescription = () => {
    const description = i18n.t('og_description');
    const metaDescription = document.getElementById('og-description');
    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    }
  };

  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const countryCode = response.data.country;
        const detectedLanguage = spanishSpeakingCountries.includes(countryCode) ? 'es' : 'en';
        setLanguage(detectedLanguage);
        i18n.changeLanguage(detectedLanguage).then(() => updateMetaDescription());
      })
      .catch((error) => {
        console.error('Error fetching visitor location:', error);
        setLanguage('en');
        i18n.changeLanguage('en').then(() => updateMetaDescription()); // Set the default language to English in case of an error
      });
  }, []);
*/

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div className="content-wrapper">
                  <HeroSection />
                </div>
                <WhatAreCircles />
                <div className="content-wrapper">
                <CirclesSection
                  circlesData={circlesData}
                  language={language}
                  isHomepage={true}
                  className="homepage-circles-section"
                />
                </div>
                <InstitutionalSection />
              </>
            }
          />
          <Route path="/plc/:id" element={<PLCDetailsWrapper />} />
          <Route
            path="/circles"
            element={
              <CirclesPage language={language} />
            }
          />
          <Route path="/institutional-service" element={<InstitutionalServicePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

function PLCDetailsWrapper() {
  const { id } = useParams();
  const circle = getCircleById(id);

  if (!circle) {
    return <NotFound />;
  }

  return <PLCDetails circle={circle} />;
}

export default App;