import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PLCDetails.css';
import peopleIcon from '../../images/school.png';
import studentsIcon from '../../images/students.png';
import copyIcon from '../../images/copyIcon.png';
import { useTranslation } from 'react-i18next';
import tabletop from '../../images/tabletop.png';
import tabletopMobile from '../../images/tabletop-mobile.png';

function PLCDetails({ circle }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const circleUrl = `https://myteachercircles.com/plc/${circle.id}`;
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedSmall, setIsCopiedSmall] = useState(false);

  const handleBackButtonClick = () => {
    navigate('/circles');
  };

  const joinPLC = t('joinPLC');
  const circleFor = t('circleFor');

  const handleCopyClick = () => {
    const copyText = `${joinPLC}\n\n${circleFor} ${circle.shortDescription}. ${circleUrl}`;
    navigator.clipboard.writeText(copyText);
    setIsCopied(true);
  };

  const handleInstructionCopyClick = () => {
    navigator.clipboard.writeText(circle.accessInstructions);
    setIsCopiedSmall(true);
  };

  return (
    <section className="plc-details">
      <button onClick={handleBackButtonClick}>{t('back_btn')}</button>

      <div className="plc-image-desktop">
        <img src={tabletop} />
      </div>
      <div className="plc-image-mobile">
        <img src={tabletopMobile} />
      </div>

      <h2>{circle.title}</h2>
      
      <div
        className="topic-tag"
        style={{ backgroundColor: circle.backgroundColor }}
      >
        {t(circle.topic)}
      </div>
      
      <div className="plc-counter">
        <img src={peopleIcon} alt={t('teachers_enrolled')} title={t('teachers_enrolled')} />
        <span className="plc-counter-text">
          {t('teachers_enrolled')}: <span className="enrolled-count">{circle.enrolled}</span>
        </span>
      </div>

      <div className="plc-students-age">
        <img src={studentsIcon} alt={t('students_age')} title={t('students_age')} />
        <span className="plc-students-age-text">
          {t('students_age')}: <span className="age-range">{circle.studentsAge}</span>
        </span>
      </div>

      <h3>{t('description_title')}</h3>
      <p>{circle.descriptionP1}</p>
      <p>{circle.descriptionP2}</p>

      <ul>
        {circle.descriptionItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>

      <p>{t('guide_url')}
      <a
            href={circle.guideUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('here')}
          </a>.
      </p>

      <h3>{t('access_instructions')}</h3>
      <ol>
        <li>
          {t('access_instructions_detail_1')}
          <a
            href="https://apps.apple.com/us/app/wearesix/id1584686101"
            target="_blank"
            rel="noopener noreferrer"
          >
            iPhone
          </a>{' '}
          {t('or')}{' '}
          <a
            href="https://play.google.com/store/apps/details?id=wearesix.io.staging"
            target="_blank"
            rel="noopener noreferrer"
          >
            Android
          </a>
        </li>
        <li>
          {t('access_instructions_detail_2')} <b>{circle.accessInstructions}</b>
          <button onClick={handleInstructionCopyClick} className="instruction-copy-button mobile-only" style={{border: 'none', background: 'transparent'}}>
            <img src={copyIcon} alt={t('copy_to_clipboard')} className="instruction-copy-icon" style={{height: '1em', verticalAlign: 'top'}} />
            <span className={isCopiedSmall ? 'copied-text' : ''}>{isCopiedSmall ? t('copied') : ''}</span>
          </button>
        </li>
        <li>{t('access_instructions_detail_3')}</li>
      </ol>

      <h3>{t('share_circle')}</h3>
      <p>{t('share_circle_instructions')}</p>
      <button onClick={handleCopyClick} className="copy-link">
        <img src={copyIcon} alt={t('copy_to_clipboard')} className="share-icon" />
        <span>{isCopied ? t('copied') : ''}</span>
      </button>
    </section>
);
}

export default PLCDetails;