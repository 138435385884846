import React from 'react';
import { useTranslation } from 'react-i18next';
import './StepsSection.css';
import step1Image from '../../images/Step1.png';
import step2Image from '../../images/Step2.png';
import step3Image from '../../images/Step3.png';

function StepsSection() {
  const { t } = useTranslation();

  return (
    <section className="steps-section">
      <h2>{t('steps_title')}</h2>
      <div className="steps-grid">
        <div className="step-box">
          <img src={step1Image} alt="Step 1" />
          <h3>{t('step1_title')}</h3>
          <p>{t('step1_description')}</p>
        </div>
        <div className="step-box">
          <img src={step2Image} alt="Step 2" />
          <h3>{t('step2_title')}</h3>
          <p>
            {t('step2_description')}{' '}
            <a
              href="https://apps.apple.com/us/app/wearesix/id1584686101"
              target="_blank"
              rel="noopener noreferrer"
            >
              iPhone
            </a>{' '}
            {t('or')}{' '}
            <a
              href="https://play.google.com/store/apps/details?id=wearesix.io.staging"
              target="_blank"
              rel="noopener noreferrer"
            >
              Android
            </a>
          </p>
        </div>
        <div className="step-box">
          <img src={step3Image} alt="Step 3" />
          <h3>{t('step3_title')}</h3>
          <p>{t('step3_description')}</p>
        </div>
      </div>
    </section>
  );
}

export default StepsSection;


