import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Header.css';
import logo from '../../images/teacher-circles-logo.png';
import hamburgerIcon from '../../images/hamburgerIcon.png';
import { useTranslation } from 'react-i18next';

function Header() {
  const { t } = useTranslation();

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="logo-sandwich-wrapper">
          <div className="sandwich-menu" onClick={toggleMenu}>
            <img src={hamburgerIcon} alt="Menu" />
          </div>
          <Link to="/" className="logo">
            <img src={logo} alt={t('teaching_circles_logo')} />
          </Link>
        </div>
        <nav className={`main-nav${menuOpen ? ' open' : ''}`}>
          <ul>
            <li>
              <NavLink exact to="/" activeClassName="active" onClick={toggleMenu}>
                {t('home')}
              </NavLink>
            </li>
            <li>
              <NavLink to="/circles" activeClassName="active" onClick={toggleMenu}>
                {t('circles')}
              </NavLink>
            </li>
            <li>
              <NavLink to="/institutional-service" activeClassName="active" onClick={toggleMenu}>
                {t('institutions')}
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" activeClassName="active" onClick={toggleMenu}>
                {t('about')}
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" activeClassName="active" onClick={toggleMenu}>
                {t('contact')}
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;