import React from 'react';
import { Link } from 'react-router-dom';
import './IndividualPLC.css';
import peopleIcon from '../../images/school.png';
import { useTranslation } from 'react-i18next';

function IndividualPLC({ id, title, shortDescription, backgroundColor, studentsAge, enrolled }) {
  const { t } = useTranslation();

  return (
    <div className="individual-plc" style={{ backgroundColor }}>
      <div className="enrollment-counter">
        <img src={peopleIcon} alt={t('teachers_enrolled')} title={t('teachers_enrolled')} />
        <span>{enrolled}</span>
      </div>
      <h3>{title}</h3>
      <span className="students-age-text">({studentsAge} {t('years')})</span>
      <p>{shortDescription}</p>
      <Link to={`/plc/${id}`} className="access-plc-btn">
        {t('access_plc_btn')}
      </Link>
    </div>
  );
}

export default IndividualPLC;





