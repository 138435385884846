import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';

function NotFound() {
  return (
    <div className="not-found">
      <h2>404: Page Not Found</h2>
      <p>Sorry, the page you were looking for could not be found.</p>
      <Link to="/">Go back to the homepage</Link>
    </div>
  );
}

export default NotFound;
