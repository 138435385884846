import React from 'react';
import './AddCircleCard.css';
import { useTranslation } from 'react-i18next';

function AddCircleCard({ onAddButtonClick }) {
  const { t } = useTranslation();

  return (
    <div className="add-circle-card">
      <h3>{t('add_circle')}</h3>
      <button className="add-circle-btn" onClick={onAddButtonClick}>
        {t('add_button')}
      </button>
    </div>
  );
}

export default AddCircleCard;


