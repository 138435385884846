import React from 'react';
import contactImage from '../../images/contact-us.png';
import './Contact.css';
import { useTranslation } from 'react-i18next';

function Contact() {
  const { t } = useTranslation();
  
  return (
    <section className="contact-section">
      <img src={contactImage} alt={t('contact_us')} className="contact-image" />
      <h2>{t('contact_us')}</h2>
      <p>
        {t('contact_paragraph_1')} <a href="https://www.linkedin.com/in/smarambio/" target="_blank" rel="noopener noreferrer">{t('sebastian')}</a> {t('contact_paragraph_2')} <a href="mailto:sebastian@wer6.io">sebastian@wer6.io</a>. {t('contact_paragraph_3')}<br /><br />
      </p>
    </section>
  );
}

export default Contact;

