import React from 'react';
import { useTranslation } from 'react-i18next';
import IndividualPLC from '../IndividualPLC/IndividualPLC';
import { Link } from 'react-router-dom';
import './CirclesSection.css';
import AddCircleCard from '../AddCircleCard/AddCircleCard'; // Import AddCircleCard


function CirclesSection({ circlesData, language, isHomepage = false, className = '', hideTitle = false, onAddButtonClick }) {
  const { t } = useTranslation();
  const filteredCircles = language
    ? circlesData.filter((circle) => circle.language === language)
    : circlesData;
  const displayedCircles = isHomepage ? filteredCircles.slice(0, 4) : filteredCircles;

  return (

    <section className={`circles-section ${className}`} id="circles">
      {!hideTitle && <h2>{t('explore_circles')}</h2>}
      <div className="circles-grid">
      {displayedCircles.map((circle, index) => {
        if (index === 0 && circle.isAddCircle) {
          return (
            <div className="add-circle-card-container" key="add-circle-card">
              <AddCircleCard onAddButtonClick={onAddButtonClick} />
            </div>
          );
        }

        return (
          <IndividualPLC
            key={circle.id}
            id={circle.id}
            title={circle.title}
            shortDescription={circle.shortDescription}
            backgroundColor={circle.backgroundColor}
            enrolled={circle.enrolled}
            studentsAge={circle.studentsAge}
            guideUrl={circle.guideUrl}
          />
        );
      })}
      </div>
      {isHomepage && (
        <div className="all-circles-btn">
          <Link to="/circles" className="btn">
            {t('check_all_circles')}
          </Link>
        </div>
      )}
    </section>
  );
}

export default CirclesSection;


