import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './InstitutionalSection.css';
import feature1Icon from '../../images/feature1-icon.png';
import feature2Icon from '../../images/feature2-icon.png';
import feature3Icon from '../../images/feature3-icon.png';

function InstitutionalSection() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/institutional-service');
  };

  return (
    <div className="institutional-container">
      
        <section className="institutional-section">
          <h2>{t('institutional_title')}</h2>
          <p>{t('institutional_description')}</p>
        </section>
        
        <section className="features-section">
          <div className="features-grid">
            <div className="feature">
              <img src={feature1Icon} alt={t('feature1_title')} />
              <h3>{t('feature1_title')}</h3>
              <p>{t('feature1_description')}</p>
            </div>
            <div className="feature">
              <img src={feature2Icon} alt={t('feature2_title')} />
              <h3>{t('feature2_title')}</h3>
              <p>{t('feature2_description')}</p>
            </div>
            <div className="feature">
              <img src={feature3Icon} alt={t('feature3_title')} />
              <h3>{t('feature3_title')}</h3>
              <p>{t('feature3_description')}</p>
            </div>
          </div>
        </section>

        <section className="institutional-section">
          <button className="btn" onClick={handleButtonClick}>{t('learn_more')}</button>
        </section>

    </div>
  );
}

export default InstitutionalSection;


