import React from 'react';
import './WhatAreCircles.css';
import aboutCirclesImage from '../../images/teachers.png'; // Replace with your desired image
import { useTranslation } from 'react-i18next';

function WhatAreCircles() {
  const { t } = useTranslation();

  return (
    <section className="what-are-circles">
      <div className="container">
        <div className="image-container">
          <img src={aboutCirclesImage} alt={t('what_are_circles_image_alt')} />
        </div>
        <div className="text-container">
          <h2>{t('what_are_circles_title')}</h2>
          <p>{t('what_are_circles_description')}</p>
          <p>{t('join_circles')}</p>
        </div>
      </div>
    </section>
  );
}

export default WhatAreCircles;
