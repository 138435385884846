import React from 'react';
import './HeroSection.css';
import heroImage from '../../images/hero-image.png';
import { useTranslation } from 'react-i18next';

function HeroSection() {

  const { t } = useTranslation();

  const scrollToCircles = () => {
    const circlesElement = document.getElementById('circles');
    circlesElement.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className="hero-section">
      <div className="hero-content">
        <h1>{t('hero_title')}</h1>
        <p>{t('hero_subtitle')}{(' ')}<b>{t('hero_subtitle2')}</b></p>
        <button className="btn hero-button" onClick={scrollToCircles}>
          {t('hero_button')}
        </button>
      </div>
      <div className="hero-image-container">
        <img src={heroImage} alt="Teachers collaborating in a meeting" />
      </div>
    </section>
  );
}

export default HeroSection;
